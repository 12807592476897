import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue')
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('../views/Mobile.vue')
  },
  {
    path: '/signup/:id',
    name: 'signup',
    component: () => import('../views/auth/Signup.vue')
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/auth/Profile.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  const store = await import('../store')
  const user = store.default.getters.user
  if (to.name !== 'login' && to.name !== 'signup' && !user.loggedIn) next({ name: 'login' })
  else next()
})

export default router
