import { Firestore } from '../firebase'
import IUser from '../user/IUser'
import IFamily from './IFamily'

export default class Family implements IFamily {
  readonly id: string|null

  constructor (id: string) {
    this.id = this.SetValidId(id)
  }

  CreateMembership (user: IUser) {
    if (!this.id) {
      return
    }

    const familyCollection = Firestore.collection('families')
    const userCollection = Firestore.collection('users')
    const membershipCollection = familyCollection.doc(this.id).collection('memberships')
    membershipCollection
      .add({
        user: userCollection.doc(user.uid)
      })
  }

  SetValidId (id: string): string|null {
    // check if id is valid
    if (!this.IsValidId(id)) {
      return null
    }
    return id
  }

  IsValidId (id: string|null): boolean {
    if (!id) {
      return false
    }
    // Id should have a length of 20 and includes uppercase, lowercase and digits
    return id.length === 20 && id.toUpperCase() !== id && id.toLowerCase() !== id && id.match(/\d/) !== null
  }
}
